.container {
  max-width: 1200px;
  margin: 0 auto;
}

@media (max-width: 1240px) {
  .container {
    margin: 0 20px;
  }
}

@media (max-width: 480px) {
  .container {
    margin: 0;
  }
}
