.title {
  display: block;
  margin-top: 40px;

  font-weight: 600;
  font-size: 24px;
}

@media (max-width: 768px) {
  .title {
    margin-top: 20px;
    margin-bottom: 0;
    
    font-size: 18px;
  }
}