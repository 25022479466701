.text {
  margin: 0;

  &:not(:last-of-type) {
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .text {
    font-size: 16px;
  
    &:not(:last-of-type) {
      margin-bottom: 10px;
    }
  }
}