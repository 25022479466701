.page-main {
  display: block;
  padding-bottom: 30px;

  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
