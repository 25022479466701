.section {
  padding: 30px;

  background-color: #ffffff;
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.2);

  &:first-of-type {
    margin-bottom: 30px;
  }
}

@media (max-width: 992px) {
  .section {
    &:not(:last-of-type) {
      margin-bottom: 20px;
    }
  }
}

@media (max-width: 480px) {
  .section {
    padding: 20px;

    background-color: transparent;
    box-shadow: none;

    &:not(:last-of-type) {
      margin-bottom: 0;
    }
  }
}
