.facts__list {
  &:last-of-type {
    margin-bottom: 40px;
  }
}

@media (max-width: 768px) {
  .facts__list {
    &:last-of-type {
      margin-bottom: 20px;
    }
  }
}
