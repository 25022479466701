@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Regular.woff2") format("woff2"),
    url("../fonts/Poppins-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Medium.woff2") format("woff2"),
    url("../fonts/Poppins-Bold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Bold.woff2") format("woff2"),
    url("../fonts/Poppins-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0 auto;

  font-family: "Poppins", "Arial", sans-serif;
  font-size: 18px;
  line-height: 1.5;

  background-image: linear-gradient(45deg, rgba(130, 77, 238, 0.05), rgba(64, 16, 160, 0.1));
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
}

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}
