.page-header {
  margin-bottom: 30px;
  padding: 30px 0;

  background-image: linear-gradient(45deg, #824dee, #4010a0);
}

.page-header__logo {
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.page-header__logo-icon {
  fill: #ffffff;
}

@media (max-width: 992px) {
  .page-header {
    margin-bottom: 20px;
  }

  .page-header__logo-icon {
    width: 188px;
    height: 93px;
  }
}

@media (max-width: 768px) {
  .page-header {
    padding: 20px 0;
  }

  .page-header__logo-icon {
    width: 188px;
    height: 93px;
  }
}

@media (max-width: 480px) {
  .page-header {
    margin-bottom: 0;
  }
}