.heading {
  margin: 0;
  margin-bottom: 20px;

  font-weight: 600;
  font-size: 32px;
}

@media (max-width: 768px) {
  .heading {
    margin-bottom: 10px;
    font-size: 24px;
  }
}
